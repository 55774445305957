import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';


const CollegeWritingServicePage = () => (
  <Layout
    pageWrapperClassName="college-writing-service-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="College Essay Writing Service - College Paper Help"
      meta={[
        {
          name: 'description',
          content: 'TakeAwayEssay.com 📝 is a Custom College Essay Service that offers customers professional writers, 24/7 customer support, and money-back guarantees. With its years of experience and numerous positive customer reviews',
        },

        {
          property: 'og:title',
          content: 'Custom College Essay Writing Service from a Reliable Company | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content: 'Custom college essay writing service from a reliable company online, top-quality assignments at affordable prices. Day and night availability, urgent deadlines, compliance with all your requirements, buy your paper now and get 100% unique content.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/college-writing-service.html' },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        Submit the best essays
        <br className="first-screen-section__title__br" />
        {' '}
        in your college
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        Surprise your teachers with a little bit of help
        <br className="first-screen-section__heading__br" />
        {' '}
        from our writing experts
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        College paper help: college essay writing service
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            Being a college or university student can be very demanding at times. You have to write
            many academic papers during a term (semester or quarter). We know well that many
            students come to university without the necessary writing skills for undergraduate
            studies.
          </p>
          <p>
            This can be a serious problem ever since the application to enter university.{' '}
            <strong>
              Many colleges and universities ask applicants to write a personal statement during the
              admission process. If you cannot write academic papers by yourself, you can always pay
              to have someone help you.{' '}
            </strong>
            Use a college essay writing service provider and let us help you manage this problem.
          </p>
          <p>
            How does a college paper writing service provider work? It’s not complicated. Some
            companies offer these services online. Generally, you just have to visit their website,
            complete an order form, and send it. Shortly after that, a professional writer will
            start crafting a custom paper according to your specifications.
          </p>
          <h2 className="h3">You have to use the best college paper writing service only</h2>
          <p>
            Now, not all these companies are equal. Does the company deliver custom college essays
            on time? Does the company have a non plagiarism policy in place? Asking this type of
            question is important. If you are going to spend money on the service, make sure you get
            the best college essay writing service available.
          </p>
          <p>
            This is why you must consider{' '}
            <a href="https://takeawayessay.com/">https://takeawayessay.com/</a>.{' '}
            This is one of the
            best writing service provider that you can find online. There are many reasons. First,
            the quality of their papers and essays is high. However, their prices are affordable.
            This is important. Most students don’t have much money to purchase an essay.
          </p>
          <p>
            Moreover, this company always meets deadlines. Their professional writers can work very
            fast in case of urgent orders. Hence, you never worry about late delivery with them. All
            the papers go through a meticulous editing process in which grammar errors are removed.
            Also, the editors verify the uniqueness of every paper.
          </p>
          <p>
            Many students think that this company provides the best college paper writing service.
            Now you can see why. They don’t compromise anything to deliver the best essay or
            research paper you can buy.
          </p>
          <h2 className="h3">
            Buy one of the best custom college essays to improve your writing skills
          </h2>
          <p>
            When you cannot cope with all your homework, send this company a message saying: “help
            me with my assignments, please.” They will be more than willing to help. Using this
            custom college essay writing service is your best decision. You will improve your
            understanding of your disciplines and your grades as a result.
          </p>
          <p>
            Why are these services so good? Well, they have added value and offer advantages other
            services don’t. Let us take a look:
          </p>
          <ul className="dot-list">
            <li>
              The fees the company charges are fair. We cannot say that their service is very cheap,
              but for the quality you get, the price is the right one. Most students can afford it.
            </li>
            <li>
              The company hires English-speaking writers only. This way they guarantee a better
              quality of all the cheap college essays.
            </li>
            <li>
              The company guarantees that not a single paper will contain plagiarized material.
            </li>
            <li>
              Your order will be a confidential and private matter. Hence, don’t worry that people
              who don’t have to, will find out that you paid for professional writing services.
            </li>
            <li>
              The company offers a money-back guarantee in case you cancel your order. Similarly, if
              you’re not satisfied with your paper you can ask for free revisions. If you don’t like
              the paper after said revisions, you can claim a reimbursement.
            </li>
          </ul>
          <p>
            Who else can offer such guarantees? Hands down,{' '}
            <a href="https://takeawayessay.com/">https://takeawayessay.com/</a>{' '}
            offers the best college essay writing service out there.
          </p>
          <h2 className="h3">You can easily get the best college paper writing service</h2>
          <p>
            Now you see that things can be much easier for you at college or university. You don’t
            have to stress yourself out by ignoring professional help with your homework. Look for
            assistance when you need it. There is nothing wrong with it. After all, you pay for a
            service. That’s all.
          </p>
          <p>
            Use this premium college paper writing service whenever you need quick and relatively
            cheap papers. You can use the papers you get as sources of information or inspiration to
            complete your homework. Or you can check out the vocabulary and the formatting style
            your writer has used. The decision is yours.
          </p>
          <p>
            In any case, remember that all you have to do is place an order online. That will never
            take much time. Hence, don’t wait any longer to start improving your skills.
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Buy paper">
      Actually be that guy
      <br />
      {' '}
      whose essays always rock.
    </CTA>

  </Layout>
);

export default CollegeWritingServicePage;
